/* src/components/Presentation.css */
.presentation {
    text-align: center;
    margin: 50px auto;
  }
  
  .presentation h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .presentation p {
    font-size: 1.2rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
  }
  