/* src/components/ContactForm.css */
.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .contact-form h2 {
    text-align: center;
    color: #333;
  }
  
  .contact-form label {
    margin-top: 10px;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form button {
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  